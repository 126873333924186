import { IconFacebook, IconInstagram, IconLinkedIn } from '../../components/Icons/'
import './Hauptseite.scss'
import { useState, useEffect, useMemo } from 'react' 
import qrCodeLeft from '../../assets/images/qr-left.png'
//import video1 from '../../assets/videos/video_01.mp4'
//import video2 from '../../assets/videos/video_02.mp4'
import image1 from '../../assets/images/image-480x480.jpg'  // Füge deine Bilddateien hinzu
import image2 from '../../assets/images/foot2.webp'  // Weitere Bilddateien
import image3 from '../../assets/images/henry.jpg' 
import image4 from '../../assets/images/Henry1.png'
import image5 from '../../assets/images/Henry2.png'


const Hauptseite = () => {

  const facebookLink = 'https://www.facebook.com/people/Podotherapie-Praxis-K%C3%B6ln/61553932202588/'
  const instagramLink = 'https://www.instagram.com/henrypodotherapie/'
  const linkedInLink = 'https://www.linkedin.com/in/henry-posmontier-683a438b/'

  // Diese Funktion erwartet den Pfad zu einem Video und ein Callback,
  // das mit dem Format des Videos ('landscape' oder 'portrait') aufgerufen wird.
  /**function detectVideoFormat(videoSrc, callback) {
    const video = document.createElement('video')
    video.src = videoSrc
    video.onloadedmetadata = () => {
      callback(video.videoWidth > video.videoHeight ? 'landscape' : 'portrait')
    }
  }**/

  //const [videoFormat, setVideoFormat] = useState('landscape') // Standardmäßig 'landscape'

  // Array von Video-URLs oder Pfaden
  //const videos = [video1, video2]

  // Array von Bild-URLs oder Pfaden
  const images = useMemo(() => [image1, image2, image3, image4, image5] , [])
  
  // State für das aktuelle Video
  //const [currentVideo, setCurrentVideo] = useState('')

  // State für das aktuelle Bild
  const [currentImage, setCurrentImage] = useState('')

  // Funktion, um bei jedem Laden der Komponente ein zufälliges Video auszuwählen
  /**useEffect(() => {
    const randomIndex = Math.floor(Math.random() * videos.length)
    setCurrentVideo(videos[randomIndex]);
    detectVideoFormat(currentVideo, (format) => {
      setVideoFormat(format);
    });
  }, [currentVideo]) // Diese Funktion wird aufgerufen, wenn sich `currentVideo` ändert.
  **/
  
  // Funktion, um bei jedem Laden der Komponente ein zufälliges Bild auszuwählen
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length)
    setCurrentImage(images[randomIndex]);
  }, [images]) // Diese Funktion wird nur einmal beim Laden der Komponente aufgerufen.

  return (
    <section id='hauptseite' className='hauptseite full-screen-section'>
      {/* <!-- Podotherapie Informationen --> */}
      <div className='container pt-5 pb-5'>
        <div className="row">
          <div className="col-lg-6">
            <h1 className="display-3 fw-bold">Podotherapie in Köln</h1>

            <p className='display-5 fw-bold name'>Henry Posmontier</p>
            <p className='display-5 name'>Podologe</p>
            <p className='display-5 job'>Medizinische Fußpflege</p>
            <p className='display-6 job2'>Privat und alle Kassen</p>
            <p className='display-6 name2'>Schnelle Terminvergabe</p>
            <p className='display-6 job2'><a href="tel:022129251906"><i className="bi bi-telephone-fill"></i> 0221 2925 19 06</a></p>
            <p className='display-6 job2'><a href="tel:+4915118404212"><i className="bi bi-phone-fill"></i> 0151 18 404 212</a></p>
            <p className='display-9 job2'><a href="mailto:henry@podotherapie-koeln.de"><i className="bi bi-envelope-fill"></i> henry@podotherapie-koeln.de</a></p>
          </div>

          <div className="col-lg-6 image-box">
            <img src={currentImage} className="rounded shadow-5 mt-5 img-fluid" alt="Podotherapie Bild" />
          </div>

          {/* Alte Video-Version */}
          {/*
          <div className="col-lg-6 video-box">       
            <video src={currentVideo} className={`rounded shadow-5 mt-5 video-g ${videoFormat === 'landscape' ? 'video-landscape' : 'video-portrait'}`} autoPlay muted loop></video>
          </div>
          */}
          
        </div>
        <div className="row">
          <div className="col-lg-12">
            <img src={qrCodeLeft} alt="QR Code WhatsApp" className="img-fluid rounded img1" />
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-12">
            <p className='job2'><a href={facebookLink} target='_blank' rel="noopener noreferrer"><IconFacebook /></a>&nbsp;<a href={instagramLink} target='_blank' rel="noopener noreferrer"><IconInstagram /></a>&nbsp;<a href={linkedInLink} target='_blank' rel="noopener noreferrer"><IconLinkedIn /></a></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hauptseite
