import React from 'react'
import { IconFacebook, IconInstagram, IconLinkedIn } from '../../components/Icons/'

import logo from '../../assets/images/logo.png'
import qrCodeLeft from '../../assets/images/qr-left.png'
import qrCodeRight from '../../assets/images/qr-right.png'

import './Info.scss'


const Info = () => {

 
  const facebookLink = 'https://www.facebook.com/people/Podotherapie-Praxis-K%C3%B6ln/61553932202588/'
  const instagramLink = 'https://www.instagram.com/henrypodotherapie/'
  const linkedInLink = 'https://www.linkedin.com/in/henry-posmontier-683a438b/'


  return (
    <section id='info' className='info pt-5'>
      <div className="container pt-5 pb-5">
        <div className="row align-items-center justify-content-center">
          {/* QR-Code links */}
          <div className="col d-flex justify-content-end header-qr-code">
            <a href="https://wa.me/+4915118404212?text=Podotherapie">
              <img src={qrCodeLeft} alt="QR Code Links" className="img-fluid rounded" />
            </a>
          </div>

          {/* Logo in der Mitte */}
          <div className="col logo-link-container">
            <img src={logo} className="header-logo" alt="logo" />
            <p>
              <a
                className="header-link"
                href="https://www.podotherapie-koeln.de"
                target="_blank"
                rel="noopener noreferrer"
              ><br/><br/>
                podotherapie-koeln.de
              </a>
            </p>
            {/*<WhatsAppFormPopup />*/}
          </div>

          {/* QR-Code rechts */}
          <div className="col d-flex justify-content-start header-qr-code">
            <a href='https://wa.me/+4915118404212?text=Podotherapie'>
              <img src={qrCodeRight} alt="QR Code Rechts" className="img-fluid rounded" />
            </a>
          </div>
          <div className="col-12 s-links"><br/><br/><br/><a href={facebookLink} target='_blank' rel="noopener noreferrer"><IconFacebook /></a>&nbsp;<a href={instagramLink} target='_blank' rel="noopener noreferrer"><IconInstagram /></a>&nbsp;<a href={linkedInLink} target='_blank' rel="noopener noreferrer"><IconLinkedIn /></a></div>
        </div>
      </div>
    </section>

  )
}

export default Info
