import React from 'react'
import IconAddress from '../../components/Icons/IconAddress'
import IconPerson from '../../components/Icons/IconPerson'
import IconPhone from '../../components/Icons/IconPhone'
import IconPhoneIphone from '../../components/Icons/IconPhoneIphone'
import IconWeb from '../../components/Icons/IconWeb'

import './Impressum.scss'

import henryFuß2 from '../../assets/images/henry-fuß2.jpg'
import toni from '../../assets/images/toni.jpg'
import henry from '../../assets/images/henry.jpg'

const Impressum = () => {


  const henryWeb = `https://www.podotherapie-koeln.de/#kontakt`
  const toniWeb = `https://www.tonizeidler.com/contact`
  const Aufsichtsbehörde = `https://www.stadt-koeln.de/service/adressen/gesundheitsamt`


  return (
    <section id='impressum' className='impressum'>
      <div className="container">

        <div className="row">
          <div className="col-lg-12">
            <h1 className='display-1'>Impressum</h1><hr />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 pb-4">
            <h2>Haftung für Inhalte</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <h4><IconPerson /> Henry Posmontier</h4>
            <h4><IconAddress /> Konrad-Adenauer-Ufer 79-81<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;50668 Köln<br /><br /><IconPhone /> 0221 292 51 906<br /><IconPhoneIphone /> 0151 18 404 212<br /> <a href={henryWeb}><IconWeb /> www.podotherapie-koeln.de</a></h4>
          </div>
          <div className="col-lg-4">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
          </div>
          <div className="col-lg-4">
            <img className='image-fluid rounded-4' src={henry} alt="Henry Bild" />
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-lg-12 pb-3">
            <h2>Haftung für Links</h2>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-lg-8">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
          </div>
          <div className="col-lg-4">
            <img className='image-fluid rounded-4' src={henryFuß2} alt="Henry Fuß Bild" />
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-lg-12">
            <h2>Urheberrecht</h2>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-lg-4">
            <h4><IconPerson /> Toni Zeidler</h4>
            <h4><IconAddress /> Konrad-Adenauer-Ufer 79-81<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;50668 Köln<br /><br /><IconPhone /> 0221 79 007 123<br /><IconPhoneIphone /> 0176 545 9999 8<br /> <a href={toniWeb} target='_blank' rel='noopener noreferrer'><IconWeb /> www.tonizeidler.com</a></h4>
          </div>
          <div className="col-lg-4">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;Die auf dieser Webseite präsentierten Inhalte und Werke, erstellt von Toni Zeidler, dem Betreiber dieser Seiten, unterliegen dem deutschen Urheberrecht. Toni Zeidler, als Webentwickler mit der Website <a href={toniWeb} target='_blank' rel='noopener noreferrer'>www.tonizeidler.de</a>, behält sich alle Rechte vor. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der gesetzlich festgelegten Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung von Toni Zeidler. Downloads und Kopien dieser Seite sind ausschließlich für den privaten, nicht kommerziellen Gebrauch gestattet.</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;Für die Inhalte, die nicht vom Betreiber dieser Seite, Toni Zeidler, erstellt wurden, werden die Urheberrechte Dritter beachtet und Inhalte Dritter als solche gekennzeichnet. Sollten Sie auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden solche Inhalte umgehend entfernt.</p>
          </div>

          <div className="col-lg-4">
            <img className='image-fluid rounded-4' src={toni} alt="Toni Bild" />
          </div>
        </div>

        <div className="row pt-5"><hr />
          <div className="col-lg-6">
            <h4>Aufsichtsbehörde:</h4>
          </div>
          <div className="col-lg-6">
            <IconAddress /> Gesundheitsamt Köln, Neumarkt 15-21, 50667 Köln,<br /> <IconWeb /> <a href={Aufsichtsbehörde} target='_blank' rel='noopener noreferrer'>https://www.stadt-koeln.de/service/adressen/gesundheitsamt</a>
          </div>
        </div>

        <div className="row pt-5"><hr />
          <div className="col-lg-6">
            <h4>Berufsbezeichnungen:</h4>
          </div>
          <div className="col-lg-6">
            Heilpraktiker, Heilpraktiker (Psychotherapie), Podologe und Heilpraktiker (Podologie)
          </div>
        </div>

        <div className="row pt-5"><hr />
          <div className="col-lg-6">
            <h4>Berufsbezeichnungen verliehen in:</h4>
          </div>
          <div className="col-lg-6">
            Deutschland
          </div>
        </div>

        <div className="row pt-5"><hr />
          <div className="col-lg-6">
            <h4>Es gelten die nachfolgenden berufsrechtlichen Regelungen:</h4>
          </div>
          <div className="col-lg-6">
            Gesetz über den Beruf des Podologen<br />
            Einsehbar unter: https://www.gesetze-im-internet.de/podg/
            Gesetz über die berufsmäßige Ausübung der Heilkunde ohne ärztliche Bestallung (Heilpraktikergesetz)
            Einsehbar unter: http://www.gesetze-im-internet.de/heilprg

            Durchführungsverordnung:
            Einsehbar unter: http://www.gesetze-im-internet.de/heilprgdv_1

            Berufsordnung:
            Einsehbar unter: https://www.heilpraktikerverband.de/aktuelles/recht/93-1-berufsordnung-fuer-heilpraktiker-boh.
          </div>
        </div>

        <div className="row pt-5"><hr />
          <div className="col-lg-6">
            <h4>Mitgliedschaft im Berufsverband:</h4>
          </div>
          <div className="col-lg-6">
            Deutscher Verband für Podologie (ZFD) – Landesverband Nordrhein-Westfalen e.V.
            Auf den Äckern 33
            59348 Lüdinghausen
            Telefon: 0 25 91 – 9 80 78 70, Telefax: 0 25 91 – 9 80 78 79,
            E-Mail:  nrw@podo-deutschland.de Website: www.podo-nrw.de
          </div>
        </div>

        <div className="row pt-5"><hr />
          <div className="col-lg-6">
            <h4>Angaben zur Berufshaftpflichtversicherung:</h4>
          </div>
          <div className="col-lg-6">
            Name und Sitz der Gesellschaft:
            Allianz Köln
            Geltungsraum der Versicherung: Deutschland
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            Henry Posmontier
            Konrad-Adenauer-Ufer 79
            50668 Köln
          </div>
        </div>

        <div className="row pt-5"><hr />
          <div className="col-lg-12">
            <h4 >Berufsverband Mitgliedschaft im Verband Unabhängiger Heilpraktiker e.V.</h4>
          </div>
        </div>

      </div>
    </section >
  )
}

export default Impressum
