import React from 'react'
import './ContactBoxWhatsApp.scss'

import phone_icon from '../../../assets/images/phone-icon-red.png'

const ContactBoxWhatsApp = () => {

  return (
    /* <!-- Mobile Number --> */
    <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
      <div className="contact-box_icon">
        <img className='phone-icon' src={phone_icon} alt="Phone Icon" />
      </div>
      <div className="contact-box_info">
        <a href="https://wa.me/+4915118404212?text=Henry%20Posmontier%20Podologe" className="contact-box_info--title">+49 151 18 404 212</a>
        <p className='contact-box_info--subtitle'>Mo-Do<br />Termin nach Vereinbarung</p>
      </div>
    </div>
  )
}

export default ContactBoxWhatsApp
